$(".c-slider-project").each(function(index, value) {
    var slider = tns({
        container: value,
        items: 1,
        gutter: 30,
        nav: false,
        loop: false,
        controls: true,
        mouseDrag: false,
        autoHeight: false,
        responsive: {
            576: {
                items: 2
            }
        },
    });
});

$.fn.BeerSlider = function ( options ) {
    options = options || {};
    return this.each(function() {
        new BeerSlider(this, options);
    });
};
$('.beer-slider').BeerSlider({start: 80});
