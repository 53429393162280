"use strict";

$(document).ready(function () {
  floatingFormLabels();
  customFileUpload();
  AOS.init({
    offset: 30,
    delay: 50,
    duration: 800
  });
  $("#customLocation").parent(".input-wrapper").hide();
  $("#property-location").on("input", function () {
    $("#customLocation").focus();

    if (this.value === "customLocation") {
      $("#customLocation").parent(".input-wrapper").show();
      $("#customLocation").focus();
    } else {
      $("#customLocation").parent(".input-wrapper").hide();
    }
  });
});

function floatingFormLabels() {
  $('.floating-label').each(function () {
    var inputField = $(this).find('input, textarea');

    if (inputField.val()) {
      $(this).addClass('float');
    } else {
      $(this).removeClass('float');
    }

    $('input, textarea').on('focus', function (index, element) {
      $(this).parent().addClass('float');
      console.log('focus');
    });
    $('input, textarea').on('change paste focusout', function (index, element) {
      if ($(this).val() != "") {
        $(this).parent().addClass('float');
      } else {
        $(this).parent().removeClass('float');
      }

      ;
    });
  });
}

function customFileUpload() {
  $('input[type=file]').each(function (index, element) {
    var fileElement = $(element).parent();
    var fileNameDiv = '<div class="custom-upload-filename"></div>';
    $(fileNameDiv).appendTo(fileElement);
    $(element).on('change', function () {
      $(fileElement).find(".custom-upload-filename").empty();
      var filename = $(element).val().replace(/.*(\/|\\)/, '');
      fileElement.find(".custom-upload-filename").append(filename);
    });
  });
}
"use strict";
"use strict";

// Get segments and store in variables(named after EE segment variables for simplicity)
var newURL = window.location.protocol + "://" + window.location.host + "/" + window.location.pathname;
var pathArray = window.location.pathname.split('/'),
    segment_4 = pathArray[4] || null,
    segment_3 = pathArray[3] || null,
    segment_2 = pathArray[2] || null;
segment_1 = pathArray[1] || null;
"use strict";

(function ($) {
  $(function () {//place here your code
  });
})(jQuery);