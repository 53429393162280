$(".c-slider-gallery").each(function(index, value) {
    var slider = tns({
        container: value,
        items: 1,
        gutter: 0,
        nav: false,
        loop: false,
        controls: true,
        mouseDrag: false,
        autoHeight: false,
        responsive: {
            576: {
                items: 2
            },
            992: {
                items: 4
            }
        },
    });
});


$('.image-popup-vertical-fit').magnificPopup({
    type: 'image',
    mainClass: 'mfp-with-zoom',
    gallery:{
        enabled: true,
        arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"><i class="custom-icons custom-icons-arrow-down"></i></button>'
    },

    zoom: {
        enabled: true,
        duration: 300, // duration of the effect, in milliseconds
        easing: 'ease-in-out', // CSS transition easing function

        opener: function(openerElement) {
            return openerElement.is('img') ? openerElement : openerElement.find('img');
        }
    }
});
