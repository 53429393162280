$(".c-slider-card-property").each(function(index, value) {
    var slider = tns({
        container: value,
        items: 1,
        gutter: 30,
        edgePadding: 60,
        nav: false,
        loop: false,
        controls: true,
        mouseDrag: false,
        autoHeight: false,
        responsive: {
            460: {
                items: 2
            },
            768: {
                items: 3,
                edgePadding: 0
            },
            1200: {
                items: 4
            }
        },
    });
});

$(".c-slider-card-property-footer").each(function(index, value) {
    var slider = tns({
        container: value,
        items: 1,
        gutter: 30,
        edgePadding: 60,
        nav: false,
        loop: false,
        controls: true,
        mouseDrag: false,
        autoHeight: false,
        responsive: {
            460: {
                items: 2
            },
            768: {
                edgePadding: 0
            },
            992: {
                items: 1
            },
        },
    });
});
